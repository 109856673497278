.headerContainer {
  background-color: var(--white-color);
  justify-content: center;

}

.logoContainer {
  align-items: center;
  display: flex;
}
.logoContainer img {
  width: 220px;
  height: 100px;
}

.backButton {
  position: absolute;
    left: 2px;
    top: 2px;
}


.menuContainer {
  height: auto;
  cursor: pointer;
  justify-content: center;
  display: flex;
  background-color: white;
}

.menuContainer img {
  width: 55px;
}

.sectionContainer {
  width: 100%;
  border-bottom-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  background-color: var(--white-color);
}

.sectionImage {
  border-bottom-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  width: 100%;
}

.layoutContent {
  padding: var(--layout-padding);
  /* border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius); */
  border-top-right-radius: var(--border-radius);
  min-height: 100%;
}

.sideNavigationContainer {
  position: absolute;
  right: 0;
  top: 0;
  height: 100vh;
  border: 2px solid;
  background-color: var(--green-color);
  padding: 20px;
  z-index: 1;
}

.navigationItem {
  color: var(--white-color);
  margin-bottom: 10px;
  cursor: pointer;
}

.closeContainer {
  justify-content: flex-end;
  display: flex;
  margin-bottom: 10px;
}

@media (max-width: 576px) {
  /* .logoContainer a img {
    width: 100% !important;
  } */
  .headerContainer {
    align-items: center;
  }
}
