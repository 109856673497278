.cardContainer {
  background-color: var(--white-color);
  border-radius: var(--card-radius);
  /* height: 100%; */
  min-height: 110px;
  margin-bottom: var(--card-spacing);
}

.cardContent {
  padding: 10px;
  width: 60%;
}

.cardContentTwo {
  margin: 10px;
  width: 30px;
}
