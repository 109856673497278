:root {
  --gray-color: #ebeae9;
  --green-color: #006947;
  --white-color: #fff;
  --border-radius: 45px;
  --layout-padding: 40px 30px 50px 30px;
  --card-radius: 12px;
  --bg-color: #999999;
  --max-width: 576px;
  --card-spacing: 20px;
}
* {
  font-family: 'Roboto', sans-serif;
}

html, body, #root {
  height: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: var(--max-width);
  width: 100%;
  background-color: var(--bg-color);
}

.bg-green {
  background-color: var(--green-color);
}

.inline_html_content p {
  font-size: 12px!important;
  font-family: 'Roboto'!important;
}
.bg-gray {
  background-color: var(--gray-color);
}

.bg-white {
  background-color: var(--white-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.title {
  color: var(--green-color);
  margin-bottom: 0;
  line-height: normal;
  font-size: 75px;
  text-align: center;
  font-weight: 300;
  margin-bottom: 10px;
  line-height: 0.9;
}

.subtitle {
  text-align: center;
  color: var(--green-color);
  margin-bottom: -30px;
  font-weight: bold;
  font-size: 30px;
}

.sub-title {
  font-size: 20px;
  color: var(--green-color);
  margin-bottom: 0;
  font-weight: 600;
}
.sub-title span{ 
  font-size: 12px!important;
  text-align: left!important;
  font-weight: 300!important;
}

.sub-title.small {
  font-size: 13px;
}

.cp {
  cursor: pointer;
}

.pr {
  position: relative;
}

.bold {
  font-weight: bold;
}

.pell {
  border: 1px solid hsla(0, 0%, 4%, 0.1);
}
.pell,
.pell-content {
  box-sizing: border-box;
}
.pell-content {
  height: 300px;
  outline: 0;
  overflow-y: auto;
  padding: 10px;
}
.pell-actionbar {
  background-color: #fff;
  border-bottom: 1px solid hsla(0, 0%, 4%, 0.1);
}
.pell-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: 30px;
  outline: 0;
  width: 30px;
  vertical-align: bottom;
}
.pell-button-selected {
  background-color: #f0f0f0;
}

.carousel .slide .legend {
  font-weight: bold !important;
  top: 40px !important;
  font-size: 20px !important;
  opacity: 1 !important;
  background: transparent !important;
}

.scroll-content-service-information {
  height: 100%;
  overflow: scroll;
  background-color: var(--green-color);
}

.scroll-content-service-information section, .scroll-content-service-information p, .scroll-content-service-information h1 {
  color: white;
}

.scroll-content-service-information::-webkit-scrollbar {
  display: none;
  overflow: -moz-scrollbars-none;
}



.main-title-content {
  font-weight: 100;
  padding: 15px 0;
}

.description-content {
  font-weight: 500;
}

.note-content {
  font-weight: 100;
  font-size: 15px;
}

@media(max-width: 576px) {
  .title {
    font-size: 55px;
  }
  .subtitle {
    font-size: 19px;
    margin-top: 10px;
  }
}

.transform_bg_overlay {
  background-color: var(--gray-color) !important;
}

.image-radius {
  border-top-right-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
  overflow: hidden;
}

.slider::before {
  background-color: white !important;
  display: none;
}

.slide > div {
  background-color:  var(--gray-color);
}

.header-container {
  display: flex;
}

.header-container > div:nth-child(1) {
  width: 80%;
  border-top-right-radius: var(--border-radius);
}


.brd {
  width: 100%;
  padding: 22px 30px;
  height: 100%;
  border-bottom-left-radius: var(--border-radius);
}

.homeImage {
  margin: 0 auto;
  width: 250px;
  display: flex;
}

.left-arrow-nav {
  width: 55px; 
  padding-left: 10px
}

@media(max-width: 576px) {
  .left-arrow-nav {
    width: 35px;
    /* background: var(--green-color); */
    border-radius: 50%;
    padding: 8px;
  }
}

.mainSect {
  /* border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius); */
  height: 100%;
}

.post-content {
  /* border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius); */
  border-top-right-radius: var(--border-radius);
}

.slide {
  height: 200px;
}

.carousel .slide .legend {
  top: 10px !important;
}